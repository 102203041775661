import {Link, Router, Routes, Route} from 'react-router-dom'
import Ssvg from '../components/Ssvgg';
import Hsvg from '../components/Hsvg';
import Name from '../components/Name.js';
import Me from '../me2.png';
import Hand from '../hand.png';
import '../stylesheets/PageLink.css'

function PageLink(props) {
  return (
      <div>
        <Link className='linkBox' to={`/${props.to}`}>
          <button className='linkBoxText'>
            {props.text}
          </button>
        </Link>
      </div>
  );
}

export default PageLink;