import React, { useState, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Hsvg from './Hsvg';
// import '../stylesheets/Name.css'
import '../stylesheets/Header.css'
import Name from './Name';
import PageLink from '../components/PageLink.js';



const Header = (props) => {


  useEffect(() =>{
    var icon = document.getElementById("icon");
var icon1 = document.getElementById("a");
var icon2 = document.getElementById("b");
var icon3 = document.getElementById("c");
var nav = document.getElementById('nav');
var blue = document.getElementById("blue");

icon.addEventListener('click', function() {
  icon1.classList.toggle('a');
  icon2.classList.toggle('c');
  icon3.classList.toggle('b');
  nav.classList.toggle('show');
  blue.classList.toggle('slide');

  
});

},[])








  return (
    <div className='Header'>
      <div class="hamburger-icon" id="icon">
        <div class="icon-1" id="a"></div>
        <div class="icon-2" id="b"></div>
        <div class="icon-3" id="c"></div>
        <div class="clear"></div>
      </div>
  
      <nav id="nav" className='navBox'>
        <ul className='linkList'>
          <li className='hamLink'><PageLink className='hamLink'to='' text='HOME'/></li>
          <li className='hamLink'><PageLink className='hamLink'to='about' text='ABOUT ME'/></li>
          <li className='hamLink'><PageLink className='hamLink' to='portfolio' text='PORTFOLIO' /></li>
          <li className='hamLink'><PageLink className='hamLink' to='blog' text='BLOG'/></li>
        </ul>
      </nav>
  
      <div class="dark-blue" id="blue"></div>
        {/* <Hsvg /> */}
      
      
      
    </div>
  );
};


export default Header;